<template>
  <ion-page>
    <HeaderOne v-bind:name="$t('covid.title')" />
    <ion-content :fullscreen="true">
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="large">
            {{$t('covid.title')}}
          </ion-title>
        </ion-toolbar>
      </ion-header>

      <iframe src="https://covid-19.ada.com" width="100%" height="100%" frameborder="0" title="Ada COVID-19 assessment and screener"></iframe>

    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { IonPage, IonHeader, IonToolbar, IonTitle, IonSearchbar, IonSelect, IonSelectOption, IonItem, IonContent, IonLabel } from '@ionic/vue';
import HeaderOne from '@/components/HeaderOne.vue';

export default {
  name: 'Covid19',
  components: { IonHeader, IonToolbar, IonTitle, IonPage, HeaderOne, IonSearchbar, IonSelect, IonSelectOption, IonItem, IonContent, IonLabel },
}
</script>
